<template>
  <div>
    <app-timeline v-if="keyDates.length > 0">
      <aom-timeline-item
        v-for="(keyDate, index) in keyDates"
        :key="index"
        :title="keyDate.title"
        :subtitle="formatDate(keyDate.date)"
        :time="getRelativeTime(keyDate.date)"
        :variant="getKeyDateVariant(keyDate.date)"
        :has-detail="keyDate.has_detail_page"
        :role="keyDate.has_detail_page ? 'button' : ''"
        @click="openKeyDateModal(keyDate)"
      />
    </app-timeline>

    <b-modal
      v-model="showModal"
      :title="selectedKeyDate ? selectedKeyDate.title : ''"
      centered
      ok-only
      @ok.prevent="closeKeyDateModal"
    >
      <p>
        {{ selectedKeyDate ? formatDate(selectedKeyDate.date) : "" }} {{ timezone ? `(${timezone})` : "" }}
      </p>
      <!-- eslint-disable vue/no-v-html -->
      <p
        class="mb-0"
        v-html="selectedKeyDate ? selectedKeyDate.description : ''"
      />
    </b-modal>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AomTimelineItem from "@aom-core/AomTimelineItem";
import { localeDateStringFromIsoDateTimeWithoutTimezone } from "@/libs/utils";
import useKeyDates from "@/views/apps/useKeyDates";

export default {
  components: {
    BModal,
    AppTimeline,
    AomTimelineItem,
  },
  props: {
    timezone: {
      type: String,
      default: ''
    },
    keyDates: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showModal: false,
      selectedKeyDate: null,
    };
  },

  methods: {
    formatDate(datetime) {
      return localeDateStringFromIsoDateTimeWithoutTimezone(datetime);
    },

    openKeyDateModal(keyDate) {
      if (keyDate.has_detail_page) {
        this.selectedKeyDate = keyDate;
        this.showModal = true;
      }
    },

    closeKeyDateModal() {
      this.showModal = false;
      this.selectedKeyDate = null;
    }
  },

  setup() {
    const { getRelativeTime, getKeyDateVariant } = useKeyDates();
    return {
      getRelativeTime,
      getKeyDateVariant
    };
  }
};
</script>
