import moment from "moment";

export default function useKeyDates() {
  const getRelativeTime = datetime => {
    const date = new Date(datetime.split('T').shift());
    date.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (date.getTime() === today.getTime()) {
      return 'Today';
    }
    return moment(date).from(today);
  };

  const getKeyDateVariant = datetime => {
    const mDatetime = moment(datetime).startOf("day");
    const today = moment().startOf("day");
    // Pass
    if (today.diff(mDatetime) < 0) {
      return "warning";
    }

    // Present
    if (today.diff(mDatetime) === 0) {
      return "success";
    }

    return "secondary";
  };

  return {
    getRelativeTime,
    getKeyDateVariant,
  };
}