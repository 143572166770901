<template>
  <div :if="alerts">
    <b-alert
      v-for="(alert, index) in alerts"
      v-bind:key="index"
      :variant="alert.variant"
      show
    >
      <b-link
        :target="alert.target"
        :href="alert.externalLink"
        :to="{ name: alert.link, params: { id: defaultProgram.id } }"
        class="p-2 d-flex alert-warning"
      >
        <feather-icon :icon="alert.icon" size="32" />
        <div class="ml-2">
          <h3>{{ alert.title }}</h3>
          <p>{{ alert.message }}</p>
        </div>
      </b-link>
    </b-alert>
  </div>
</template>

<script>
import { BAlert, BLink } from "bootstrap-vue";
import { mapGetters } from "vuex";
import programsService from "@/services/programsService";
import {
  makeErrorToast,
} from "@/libs/utils";

export default {
  name: "DashBoardAlerts",
  components: {
    BAlert,
    BLink,
    makeErrorToast,
    programsService
  },
  data() {
    return {
      alerts: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("programs", ["defaultProgram"]),
  },
  watch: {
    $route() {
      this.loadAlerts();
    },
  },
  methods: {
    async loadAlerts() {
      try {
        this.isLoading = true;
        const response = await programsService.getHealthAlertsByProgramId(this.$route.params.id);
        this.alerts = response.data;

        const clientSubDomain = this.defaultProgram && this.defaultProgram.client ? this.defaultProgram.client.path : '';
        const programPath = this.defaultProgram ? this.defaultProgram.path : '';
        this.alerts.forEach(alert => {
          if (alert.link === "champion-support-contact-us") {
            alert.link = null;
            alert.externalLink = `${process.env.VUE_APP_KNOWLEDGEBASE_URL}/contact?oauth_url=${window.location.origin}&client_sub_domain=${clientSubDomain}&program_path=${programPath}`;
            alert.target = "_blank";
          }
        });

      } catch (e) {
        console.log(e)
        this.$toast(makeErrorToast(e.error));
        this.$log.error(e);
      }
      this.isLoading = false;
    },
  },
  created() {
    this.loadAlerts();
  },
};
</script>
<style lang="scss">
.alert {
  margin-bottom: 20px;
  h3 {
    color: inherit;
  }
}
</style>
